.page {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    min-height: 100vh;
    margin: 0 auto;
    background-color: #fff;
    font-family: Inter, Arial, sans-serif;
    color: #000;
    padding: 0;
}

@media screen and (max-width: 1279px) {
    .page {
        padding: 0 30px;
    }
}

@media screen and (max-width: 767px) {
    .page {
        padding: 0 15px;
    }
}