.login {
    width: 30%;
    margin: 0 auto;
    padding: 0;
}

.login__form {
    display: flex;
    flex-direction: column;
}

.login__title {
    font-size: 24px;
    line-height: 29.05px;
    font-weight: 500;
    text-align: center;
    margin: 0;
    padding: 15px 0 30px 0;
}

.login__error {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin: 0;
    padding: 5px;
    color: red;
}